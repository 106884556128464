
















import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import RequestForm from '@/components/profile/RequestForm.vue';

export default Vue.extend({
  components: {
    RequestForm,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'account/IsLoggedIn',
      findByVanity: 'profiles/FindByVanity',
    }),
    profile(): ApiResource.Profile {
      return this.findByVanity(this.$route.params.VANITY);
    },
  },
  methods: {
    redirect(commission: ApiResource.Commission) {
      this.$router.push({
        name: 'commission',
        params: { COMMISSION_ID: commission.id },
      });
    },
  },
});
